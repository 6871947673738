import { Fragment, useContext, useEffect, useState } from 'react'
import { Tooltip, Switch, MultiSelect, TextInput, Skeleton } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { IconInfoCircle } from '@tabler/icons-react'
// import { useQuery } from '@tanstack/react-query'

import { Animate, ClientButton, Icon, SettingsNav, Card, ViewContext, useNavigate, Tree } from 'components/lib'
import { ApiContext } from 'context/api'
import { ErrorNotification, UpdateNotification } from 'utils/notifications'

export function GeneralSettings(props) {
    // CONTEXT
    const viewContext = useContext(ViewContext)
    const { transactions, handleFetchListRequest, handleFilterExcludedAccountsRequest, lists, handleFetchCompanyProfileRequest, handleUpdateCompanyRequest, companyProfile /* handleCheckClassLocationStatusRequest */ } = useContext(ApiContext)

    // CONSTANTS
    const navigate = useNavigate()
    const pathname = window.location.pathname
    const companyId = pathname.split('/')[2]
    const id = companyId === ':companyId' ? companyProfile?.id : companyId
    // Get the first client admin from the clients array
    const clientAdmin = companyProfile?.clients?.find((client) => client?.permission === 'user')

    // STATES
    const [syncedAccounts, setSyncedAccounts] = useState()
    const [syncedMissingFields, setSyncedMissingFields] = useState()
    const [dashboardNote, setDashboardNote] = useState('')
    const [syncedStartDate, setSyncedStartDate] = useState('')
    const [excludedAccounts, setExcludedAccounts] = useState()
    const [allowCreateNewVendorCustomer, setAllowCreateNewVendorCustomer] = useState()
    const [allowCreateNewCategory, setAllowCreateNewCategory] = useState()
    const [allowCreateNewClass, setAllowCreateNewClass] = useState()
    const [allowCreateNewLocation, setAllowCreateNewLocation] = useState()
    const [syncedMissingFieldsData, setSyncedMissingFieldsData] = useState(['vendor', 'customer'])

    // const { data: getClassLocationStatus, isLoading: isGetClassLocationStatusLoading } = useQuery({
    //     queryFn: () => handleCheckClassLocationStatusRequest(companyId),
    //     queryKey: ['checkClassLocationStatusFromGeneralSettings'],
    // })

    // METHODS
    function handleChangeObjectProperty(lists) {
        return lists?.map((list) => ({ value: list.value, label: list.title, children: list.children }))
    }

    function handleSwitchChange(props) {
        if (!props?.clientId) return

        const clientId = clientAdmin?.id
        const isClientIncluded = (arr) => arr.includes(clientId)

        if (props?.type === 'vendor_customer') {
            setAllowCreateNewVendorCustomer((prevState) => {
                if (isClientIncluded(allowCreateNewVendorCustomer)) {
                    return prevState.filter((id) => id !== clientId)
                } else {
                    return [...prevState, clientId]
                }
            })
        } else if (props?.type === 'category') {
            setAllowCreateNewCategory((prevState) => {
                if (isClientIncluded(allowCreateNewCategory)) {
                    return prevState.filter((id) => id !== clientId)
                } else {
                    return [...prevState, clientId]
                }
            })
        } else if (props?.type === 'class') {
            setAllowCreateNewClass((prevState) => {
                if (isClientIncluded(allowCreateNewClass)) {
                    return prevState.filter((id) => id !== clientId)
                } else {
                    return [...prevState, clientId]
                }
            })
        } else if (props?.type === 'location') {
            setAllowCreateNewLocation((prevState) => {
                if (isClientIncluded(allowCreateNewLocation)) {
                    return prevState.filter((id) => id !== clientId)
                } else {
                    return [...prevState, clientId]
                }
            })
        }
    }

    function handleSyncedStartDate(date) {
        date ? setSyncedStartDate(new Date(date)) : setSyncedStartDate('')
    }

    async function handleSaveSettings() {
        if (companyProfile.editedTransactions.length) {
            return handleApproveAllTransactionsNotification()
        }

        viewContext.setLoading(true)

        const companyPayload = {
            intuitSyncedAccounts: syncedAccounts
                ? handleChangeObjectProperty(lists?.allCategories)
                      .filter((list) => syncedAccounts?.some((a) => list.value === a))
                      .map((category) => category.label)
                : companyProfile?.intuitSyncedAccounts,
            excludedAccounts: excludedAccounts
                ? handleChangeObjectProperty(lists?.allCategories)
                      .filter((list) => excludedAccounts?.includes(list.value))
                      .map((category) => category.label)
                : companyProfile?.excludedAccounts,
            syncedMissingFields: syncedMissingFields ? syncedMissingFields : companyProfile?.syncedMissingFields,
            dashboardNote: dashboardNote,
            syncedStartDate: syncedStartDate,
            allowCreateNewVendorCustomer,
            allowCreateNewCategory,
            allowCreateNewClass,
            allowCreateNewLocation,
        }

        await handleUpdateCompanyRequest({ companyId: id, ...companyPayload }, handleOnLoadActions)

        const companyProfileData = await handleFetchCompanyProfileRequest(id)

        if (companyProfileData) {
            setDashboardNote(companyProfileData?.dashboardNote)
            setSyncedStartDate(companyProfileData?.syncedStartDate)
            setAllowCreateNewVendorCustomer(companyProfileData?.allowCreateNewVendorCustomer)
            setAllowCreateNewCategory(companyProfileData?.allowCreateNewCategory)
            setAllowCreateNewClass(companyProfileData?.allowCreateNewClass)
            setAllowCreateNewLocation(companyProfileData?.allowCreateNewLocation)
            handleFetchListRequest(id, null)
        }

        return UpdateNotification({ title: 'General settings', message: 'Changes was successfully saved.' })
    }

    function handleApproveAllTransactionsNotification() {
        const message = (
            <span>
                You must approve all{' '}
                <span onClick={() => navigate(`/company/${id}/transaction`)} style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}>
                    transactions
                </span>{' '}
                before you edit the settings for this company.
            </span>
        )

        return ErrorNotification({ title: 'Transactions', message, icon: <IconInfoCircle /> })
    }

    function handleOnLoadActions(data) {
        viewContext.setLoading(false)
    }

    /* eslint-disable */
    useEffect(() => {
        // NAVIGATE
        navigate(`/company/${id}/general-settings`)

        // SET LOADER
        viewContext.setLoading(true)

        // API CALLS
        handleFetchCompanyProfileRequest(id).then((companyProfileData) => {
            if (companyProfileData) {
                setDashboardNote(companyProfileData?.dashboardNote)
                setSyncedStartDate(companyProfileData?.syncedStartDate)
                setAllowCreateNewVendorCustomer(companyProfileData?.allowCreateNewVendorCustomer)
                setAllowCreateNewCategory(companyProfileData?.allowCreateNewCategory)
                setAllowCreateNewClass(companyProfileData?.allowCreateNewClass)
                setAllowCreateNewLocation(companyProfileData?.allowCreateNewLocation)
                companyProfileData.syncedMissingFields && setSyncedMissingFieldsData((prev) => [...prev, ...companyProfileData.syncedMissingFields])
            }
        })

        handleFetchListRequest(id, handleOnLoadActions).then((res) => {
            if (res) {
                if (companyProfile?.editedTransactions?.length) {
                    handleApproveAllTransactionsNotification()
                }
            }
        })
    }, [])

    useEffect(() => {
        if (!companyProfile) {
            companyProfile?.isClassEnabled && setSyncedMissingFieldsData((prev) => [...prev, 'class'])
            companyProfile?.isLocationEnabled && setSyncedMissingFieldsData((prev) => [...prev, 'location'])
        }
    }, [])

    return (
        <>
            <SettingsNav companyId={id} />
            {viewContext.loading ? (
                <Skeleton visible={viewContext.loading} />
            ) : (
                <Animate type='pop'>
                    <Card restrictWidth>
                        <div className='w-full flex flex-col justify-center gap-4'>
                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <span className='text-red-500'>*</span>
                                    <p className='inline font-bold text-sm'>Choose which accounts to sync</p>
                                    <Tooltip label='Type of transaction account to be sync from QBO' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    {lists?.categories && (
                                        <Tree
                                            multiple
                                            showSearch
                                            allowClear
                                            placeholder='Choose account to sync...'
                                            disabled={companyProfile?.editedTransactions?.length}
                                            list={handleFilterExcludedAccountsRequest(companyProfile?.excludedAccounts, handleChangeObjectProperty(lists?.categories))}
                                            value={
                                                syncedAccounts ||
                                                handleFilterExcludedAccountsRequest(companyProfile?.excludedAccounts, handleChangeObjectProperty(lists?.allCategories))
                                                    ?.map((category) => {
                                                        if (companyProfile?.intuitSyncedAccounts?.some((a) => category?.label?.includes(a))) {
                                                            return category.value
                                                        }
                                                    })
                                                    .filter((category) => Boolean(category))
                                            }
                                            callback={(syncedAccounts) => setSyncedAccounts(syncedAccounts)}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Sync transactions missing the following fields</p>
                                    <Tooltip label='Transactions that has the missing fields (e.g. vendor, customer, class and location)' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    {companyProfile && (
                                        <MultiSelect
                                            disabled={companyProfile?.editedTransactions?.length}
                                            data={syncedMissingFieldsData}
                                            value={syncedMissingFields || companyProfile?.syncedMissingFields}
                                            onChange={(field) => setSyncedMissingFields(field)}
                                            placeholder='Choose transaction missing fields...'
                                            searchable
                                            nothingFound='Nothing found'
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Dashboard note</p>
                                    <Tooltip label='Add note to be display in the client transaction dashboard' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>{companyProfile && <TextInput disabled={companyProfile?.editedTransactions?.length} value={dashboardNote} placeholder='Add client note...' onChange={(e) => setDashboardNote(e.target.value)} /* withAsterisk */ />}</div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Sync start date</p>
                                    <Tooltip label='Select the start date of the transactions to sync' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    {companyProfile && (
                                        <DatePicker
                                            disabled={companyProfile?.editedTransactions?.length}
                                            placeholder='Pick date'
                                            value={syncedStartDate ? new Date(syncedStartDate) : syncedStartDate}
                                            onChange={(date) => handleSyncedStartDate(date)}
                                            mx='auto'
                                            maw={400}
                                            styles={{
                                                calendarHeaderControl: {
                                                    '&[data-selected]': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                },
                                                calendarHeaderLevel: {
                                                    '&[data-selected]': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                },
                                                calendarHeaderLevelIcon: {
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white!important',
                                                    },
                                                },
                                                day: {
                                                    '&[data-selected]': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                },
                                                monthPickerControl: {
                                                    '&[data-selected]': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                },
                                                monthPickerControlActive: {
                                                    backgroundColor: 'rgb(6 182 212)',
                                                    color: '#000',
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                },
                                                yearPickerControl: {
                                                    '&[data-selected]': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                },
                                                yearPickerControlActive: {
                                                    backgroundColor: 'rgb(6 182 212)',
                                                    color: '#000',
                                                    ':hover': {
                                                        backgroundColor: 'rgb(6 182 212)',
                                                        color: 'white',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Exclude accounts from category list</p>
                                    <Tooltip label='Select account type to be excluded from the category list' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    {lists?.categories && (
                                        <Tree
                                            multiple
                                            showSearch
                                            allowClear
                                            placeholder='Choose account to exclude'
                                            disabled={companyProfile?.editedTransactions?.length}
                                            list={handleChangeObjectProperty(lists?.categories).filter((c) => !companyProfile?.intuitSyncedAccounts?.includes(c.label))}
                                            value={
                                                excludedAccounts ||
                                                handleChangeObjectProperty(lists?.categories)
                                                    ?.map((category) => {
                                                        if (companyProfile?.excludedAccounts?.includes(category.label)) {
                                                            return category.value
                                                        }
                                                    })
                                                    .filter((category) => Boolean(category))
                                            }
                                            callback={(excludedAccounts) => setExcludedAccounts(excludedAccounts)}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Allow client to create a new vendor or customer</p>
                                    <Tooltip label='The client will able to create a new vendor or customer. Note: The client needs to accept their invite before enabling this setting.' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>{companyProfile && <Switch disabled={companyProfile?.editedTransactions?.length} color='cyan' checked={allowCreateNewVendorCustomer?.includes(clientAdmin?.id)} onChange={(e) => handleSwitchChange({ clientId: clientAdmin?.id, type: 'vendor_customer' })} />}</div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Allow client to create a new category</p>
                                    <Tooltip label='The client will able to create a new category. Note: The client needs to accept their invite before enabling this setting.' color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>{companyProfile && <Switch disabled={companyProfile?.editedTransactions?.length} color='cyan' checked={allowCreateNewCategory?.includes(clientAdmin?.id)} onChange={(e) => handleSwitchChange({ clientId: clientAdmin?.id, type: 'category' })} />}</div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Allow client to create a new class</p>
                                    <Tooltip label={transactions && transactions[0]?.class?.status ? 'The client will able to create a new class' : 'You must enable Class in QuickBooks settings.'} color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>{companyProfile && <Switch disabled={companyProfile?.editedTransactions?.length} color='cyan' checked={allowCreateNewClass?.includes(clientAdmin?.id)} onChange={(e) => handleSwitchChange({ clientId: clientAdmin?.id, type: 'class' })} />}</div>
                            </div>

                            <div className='flex flex-col justify-center gap-1'>
                                <div className='flex justify-left items-center gap-1'>
                                    <p className='font-bold text-sm'>Allow client to create a new location or department</p>
                                    <Tooltip label={transactions && transactions[0]?.location?.status ? 'The client will able to create a new location or department.' : 'You must enable Location in QuickBooks settings.'} color='cyan' withArrow>
                                        <span>
                                            <Icon image='help-circle' size={14} color='blue' />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>{companyProfile && <Switch disabled={companyProfile?.editedTransactions?.length} color='cyan' checked={allowCreateNewLocation?.includes(clientAdmin?.id)} onChange={(e) => handleSwitchChange({ clientId: clientAdmin?.id, type: 'location' })} />}</div>
                            </div>

                            <div className='w-20 flex flex-col justify-center gap-1'>
                                <ClientButton disabled={viewContext.loading} bg='bg-cyan-500' icon='save' color='text-white' label='Save' callback={handleSaveSettings} />
                            </div>
                        </div>
                    </Card>
                </Animate>
            )}
        </>
    )
}
